import React from 'react'

function Footer() {
    return (
        <div className='text-center font-Poppins p-5 bg-[#E9E9E9] text-sm sm:text-sm md:text-md lg:text-lg'>
            <p>Zebu Intelligent Systems. All rights reserved.</p>
        </div>
    )
}

export default Footer