import React, { useState } from 'react'

import { ServicesContent } from '../Data/Data'

import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import { NavHashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom'
import Carousels from './Carousels';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Services() {

    const [serviceState, setServiceState] = useState("")

    const navigate = useNavigate()

    const handleService = (service) => {
        setServiceState(service)
        navigate('/services/#service')
    }

    const navigateToContact = () => {
        navigate('/contact')
    }

    return (

        <div className='font-Poppins'>
            <div className='bg-white'>
                <div className="text-center">
                    <p className='text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold p-5'>{ServicesContent.head.title}</p>
                    <p className='text-[#8A8A8A] text-sm sm:text-sm md:text-md lg:text-lg  mx-5 md:mx-20 lg:mx-40 pb-5'>{ServicesContent.head.content}</p>
                </div>
                <div className='mx-0 lg:mx-32 p-5 grid md:grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-10'>
                    {
                        ServicesContent.boxes.map((box, key) =>
                            <div className='gap-4 flex' key={key}>
                                <div className=''>
                                    <LazyLoadImage src={box.icon}
                                        placeholderSrc={box.lazyIcon}
                                        effect="blur" className='object-contain w-40'
                                    />
                                </div>
                                <div>
                                    <p className='tracking-wide font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>{box.title}</p>
                                    <p className='text-[#8A8A8A] text-sm sm:text-sm md:text-md lg:text-lg  pt-3'>{box.content}</p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className='pt-16 mb-5 mx-10 lg:mx-32'>
                <p className='text-center font-bold text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl pb-7'>Our Services</p>
                <div className='grid text-center lg:text-start md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10 '>
                    {
                        ServicesContent.OurServices.map((service, key) =>
                            <NavHashLink smooth to="/services/#service" key={key} className='cursor-pointer'>

                                <div onClick={() => handleService(service)} className="block max-h-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
                                    <p className="mb-2 text-sm sm:text-sm md:text-md lg:text-lg  font-[700] tracking-wide text-black">{service.title}</p>
                                    <p className="font-normal text-[#8A8A8A] line-clamp-3 text-sm sm:text-sm md:text-md lg:text-lg">{service.description}</p>
                                    <div className='pt-5 flex justify-end'>
                                        <BsFillArrowRightCircleFill />
                                    </div>

                                </div>
                            </NavHashLink>
                        )
                    }
                </div>
                {serviceState !== "" &&
                    <div id='service' className='w-full pt-10'>
                        <p className='text-center text-xl md:text-2xl lg:text-3xl font-bold p-5'>{serviceState.title}</p>
                        <div className='bg-white p-5 rounded-lg'>
                            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2'>
                                <div className='flex justify-center'>
                                    <img src={serviceState.image} alt='img' className='object-fit w-96 justify-center rounded-lg bg-[#D9D9D9] transition-all duration-300 filter grayscale hover:grayscale-0' />
                                </div>
                                <div className='text-[#8A8A8A] flex justify-center items-center text-justify mx-10 pt-5 lg-pt-0'>
                                    <p>{serviceState.content}</p>
                                </div>
                            </div>
                            <p className='pt-5 text-sm text-justify lg:text-start text-[#8A8A8A]'>{ServicesContent.CommonText.content}</p>
                            <div className="mt-5 text-center">
                                <button type="submit" className="rounded-full bg-black mt-0 px-7 py-2 text-sm font-semibold text-white" onClick={navigateToContact}>
                                    Get In Touch
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <div className='mt-8 mb-14 bg-white text-center font-extrabold p-5'>
                <div className='text-xl sm:text-xl md:text-2xl lg:text-3xl'>Some of our Customers</div>
                {/* <div className='mt-8 grid grid-cols-3 gap-5 md:grid md:grid-cols-3 md:gap-5 text-center lg:flex lg:justify-center lg:items-center lg:gap-5'> */}

                <div className='mt-8 lg:pt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-center lg:flex lg:justify-evenly lg:gap-5'>
                    {
                        ServicesContent.Customers.map((image, key) =>
                            <div key={key} className='flex justify-center'>
                                <LazyLoadImage src={image}
                                    placeholderSrc={ServicesContent.lazyCustomerImages[key]}
                                    effect="blur" className='object-contain max-w-[150px] min-w-[50px]'
                                />
                            </div>
                        )
                    }
                </div>

            </div>

            <div className='bg-white p-5 pb-16 lg:px-32'>
                <p className='font-bold text-sm sm:text-sm md:text-md lg:text-lg  text-center'>{ServicesContent.MoreInfo.title}</p>
                <div className='pt-10'>
                    <div className='bg-white p-5'>
                        <div className='lg:flex'>
                            <div className='w-full h-auto'>
                                <Carousels />
                            </div>
                            <div className='flex items-center justify-center mx-5'>
                                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore nostrum quasi, eius fugit labore error, ullam adipisci eligendi delectus nam laborum ipsum. Praesentium minus nobis amet provident, reiciendis nam ipsa? */}
                                <p className='pt-5 lg:pt-0 text-start sm:text-justify text-sm sm:text-sm md:text-md lg:text-lg '>{ServicesContent.MoreInfo.carousel[0].content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mx-5 lg:mx-72 pt-5'>
                <div className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>
                    {
                        ServicesContent.GetInTouch.title.map((title, key) =>
                            <p key={key}>{title}</p>
                        )
                    }
                </div>
                <div className='inline-block cursor-pointer my-5 rounded-full bg-black px-5 py-2 mt-5 text-white' onClick={navigateToContact}>
                    <p className=''>Get in touch</p>
                </div>
            </div>

        </div >
    )
}

export default Services