import React from 'react'
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFlip, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { ServicesContent } from '../Data/Data';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function Carousels() {
    return (
        <div className='bg-white'>
            <Swiper
                autoHeight={true}
                spaceBetween={10}
                centeredSlides={false}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper h-80 w-80"
            >
                {
                    ServicesContent.Carousels.map((image, key) =>
                        <SwiperSlide key={key}>
                            <LazyLoadImage src={image}
                                placeholderSrc={ServicesContent.lazyCarouselsImages[key]}
                                effect="blur" className='object-contain rounded-lg transition-all duration-300 filter grayscale hover:grayscale-0 '
                            />
                            {/* <img src={image} className="object-contain rounded-lg transition-all duration-300 filter grayscale hover:grayscale-0 " alt="..." /> */}
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    )
}

export default Carousels